.userManagement {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.heading {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
}

.input,
.select,
.customSelect {
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  flex: 1;
  min-width: 200px;
  background-color: #ffffff;
  color: #333;
}

.button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #45a049;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

.table tr:hover {
  background-color: #f5f5f5;
}

.changeActions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.newRow {
  background-color: #e6ffe6;
}

.customSelect {
  position: relative;
}

.selectTrigger {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
}

.optionsList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.option {
  padding: 10px;
  cursor: pointer;
  color: #333;
}

.option:hover,
.option.selected {
  background-color: #f0f0f0;
}

/* ... (keep other existing styles) */